<template>
    <div v-if="showEmailSeq" class="flex justify-between max-h-full w-full lg:p-6 flex-col lg:flex-row space-x-4 lg:space-x-6 p-4 base-content">
        <div class="lg:w-1/2">
            <label for="#" class="px-0.5 pb-1">Default Subject Email Sequence</label>
            <dropdown  reposition searchable placeholder="Default Candidate Email Sequence" :options="email_option.options" @input="(value) => setValue(value, 'candidate')" :value="selected_sequence.candidate" :limit="1" maxWidth="100%" minWidth="100%" :allowEmpty="false" :config="{ label: 'name', trackBy: 'id', allowEmpty: false }" :multiple="false" :disabled="disableEmailSequence || this.$route.params.action == 'view'" :loading="email_option.isLoading" :taggable="false" />
        </div>
        <div class="lg:w-1/2">
            <label for="#" class="px-0.5 pb-1">Default Referee Email Sequence</label>
            <dropdown  reposition searchable placeholder="Default Referee Email Sequence" :options="email_option.options" @input="(value) => setValue(value, 'referee')" :value="selected_sequence.referee" :limit="1" :loading="email_option.isLoading" :disabled="disableEmailSequence || this.$route.params.action == 'view'" :allowEmpty="false" maxWidth="100%" minWidth="100%" :config="{ label: 'name', trackBy: 'id' }" :multiple="false" :taggable="false" />
        </div>
    </div>
    <div v-else class="w-full flex bg-card-bg shadow rounded-xl py-10 justify-center">
        <div class="flex items-center gap-6 flex-col">
            <DelviumLoader />
            <div class="">Loading</div>
        </div>
    </div>
</template>

<script>
import Dropdown from "@shared/components/dropdown-base";
import axios from "@/axios";
import { mapGetters } from "vuex";
import { checkPermission } from "@shared/utils/functions";
import DelviumLoader from "@shared/loader";

export default {
    components: {
        Dropdown,
        DelviumLoader,
    },
    data() {
        return {
            package_id: "",
            email_option: {
                options: [],
                referee: [],
                isLoading: false,
            },
            selected_sequence: {
                candidate: null,
                referee: null,
            },
            showEmailSeq: false,
        };
    },
    computed: {
        ...mapGetters(["getTenantId"]),
        disableEmailSequence(){
            return this.email_option.isLoading || !this.checkPermission('package.edit') || !this.checkPermission('sequence.read')
        }
    },
    async mounted() {
        this.package_id = this.$route.params.packageName;
        await this.fetchEmailSequences();
        await this.getSelectedEmailSequences();
    },
    methods: {
        checkPermission,
        setValue(val, type) {
            let body = {
                package_id: this.package_id,
                default_email_sequence: [
                    {
                        email_sequence_id: val.id,
                        external_id_type: type,
                    },
                ],
            };
            console.log("val :>> ", val);
            this.selected_sequence[type] = val;
            this.setNewEmailSequence(body);
        },

        async fetchEmailSequences() {
            try {
                this.email_option.isLoading = true;
                const url = `/email-sequence/linearlist?external_id=${this.getTenantId}`;
                let { data } = await axios(url);
                if (data) {
                    this.email_option.options = data || [];
                }
                this.email_option.isLoading = false;
            } catch (error) {
                console.log("error :>> fetchEmailSequences ", error);
            }
        },

        async getSelectedEmailSequences() {
            try {
                const { data } = await axios(`/screening-package/${this.package_id}/default-email-sequence`);
                if (data.data && data.data.length) {
                    console.log("data.data :>> ", data.data);
                    data.data.forEach((seq) => {
                        this.selected_sequence[seq.external_id_type] = this.email_option.options.find((ems) => ems.id === seq.email_sequence_id) || null;
                    });
                }
                this.showEmailSeq = true;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        async setNewEmailSequence(payload) {
            try {
                const { data } = await axios.post(`screening-package/update-default-email-sequence`, payload);

                // console.log('data :>> ', data);
                if (data) {
                    this.$toast.success(data.message || "Default Email Sequence Updated");
                }
            } catch (error) {
                console.log("error :>> ", error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .dropdown {
        border-color: #dedede;
        @apply bg-card-bg border-box-stroke;
    }
}
</style>
